/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { message } from 'ant-design-vue';
import router from '@/router/index';

let baseURL: any = '/api';

if (process.env.NODE_ENV === 'development') {
    // 开发环境逻辑
    baseURL = '/api';
} else {
    // 生产环境逻辑
    console.log(window.location.hostname);
    if(window.location.hostname?.includes('tkqbpc.ybdaas.cn')) baseURL = 'https://tkqb.ybdaas.cn';
    else baseURL = 'https://kqb.ybdaas.cn';
    
}

const request = axios.create({
    baseURL: baseURL,
    timeout: 60000, // 请求超时时间
});

// 请求拦截器
request.interceptors.request.use((config: any) => {
    // 可以在这里添加例如token等请求头
    config.headers['Authorization'] = localStorage.getItem('token');
    config.headers['token'] = localStorage.getItem('token');
    return config;
}, (error: any) => {
    // 请求错误处理
    return Promise.reject(error);
});

// 响应拦截器
request.interceptors.response.use((response: any) => {
    // 对响应数据做处理，例如只返回data部分
    if (response.config.responseType == 'blob') {
        if (response.status == 200) {
            return response.data;
        } else {
            message.error(response.data.msg);
            return response.data;
        }
    } else if (response.data.code == 200) {
        return response.data;
    } else if (response.data.code == 1003) {
        router.push('/login');
        return response.data;
    } else if (response.data.code == 401) {
        localStorage.removeItem('token');
        return response.data;
    } else {
        message.error(response.data.msg);
        return response.data;
    }
}, (error: any) => {
    // 响应错误处理
    return Promise.reject(error);
});

export default request;